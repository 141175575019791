
import Icon from '@/components/Icon.vue'
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import Line from '@/components/Line.vue'
import TheDescription from '@/modules/membership/signal/components/TheDescription.vue'
import TheSignals from '@/modules/membership/signal/components/TheSignals.vue'

export default defineComponent({
  name: 'Trend',
  components: { Icon, TheSignals, TheDescription, Line, PageWithHeader, Tab },
  props: {
    id: String,
  },
  setup () {
    const tabIndex = ref(0)

    const switchTab = (index: number) => {
      tabIndex.value = index
    }

    return {
      switchTab,
      tabIndex,
    }
  },
})
